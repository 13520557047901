import './css/info.css';
import React from 'react';
import { useSpring, animated } from 'react-spring'

const CmpInfo = () => {

    const props = useSpring({
        loop: true,
        from: { width: 40, height: 40 } ,
        to: [   {width: 30, height: 30},
                x => clickEffect(),
                {width: 40, height: 40} 
            ],
        delay: 5000,
        config: {duration: 150}

    })

    function clickEffect(){
        var d=document.createElement("div");
        d.className="clickEffect";
        d.style.top=document.getElementById('cursorIcon').getBoundingClientRect().top+"px";
        d.style.left=document.getElementById('cursorIcon').getBoundingClientRect().left+"px";
        document.body.appendChild(d);
        d.addEventListener('animationend',function(){d.parentElement.removeChild(d);}.bind(this));
        }

    return(
        <div className="CmpInfo">
            <h1>Mejor tarifa de Gas Natural</h1>
            <div className='surTitol'>
                A un solo clic.
                <animated.img 
                    style={props} 
                    className='cursorIcon' 
                    src="cursor-icon.svg" 
                    alt="Icono del cursor"
                    id="cursorIcon"
                >
                </animated.img>
            </div>
            <p>No hago ningún beneficio de la tarifa que contratas,<br/>mi política es transparencia sin letra pequeña.</p>
        </div>
    )
}

export default CmpInfo;