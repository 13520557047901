import React from 'react'
import Modal from "react-bootstrap/modal"
import Button from "react-bootstrap/Button"
import "./css/detalleTarifa.css"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import "../views/css/resultados.css"

function CmpDetalleTarifa(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Detalles de {props.compania} - {props.nombretarifa}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='CmpDetalleTarifa'>
            <tbody>
              <tr>
                <th>Consumo kWh/año</th>
                <th>Precio Variable (€/kWh)</th>
                <th>Precio Fijo (€/día)</th>
              </tr>
              <tr>
                <td>≤ 5.000 (RL1)</td>
                <td>{props.varrl1}</td>
                <td>{props.fijorl1}</td>
              </tr>
              <tr>
                <td>Entre 5.000 y 15.000 (RL2)</td>
                <td>{props.varrl2}</td>
                <td>{props.fijorl2}</td>
              </tr>
              <tr>
                <td>Entre 15.000 y 50.000 (RL3)</td>
                <td>{props.varrl3}</td>
                <td>{props.fijorl3}</td>
              </tr>
            </tbody>
          </table>
          <h3 className='CmpDetalleTarifaTitol'>Histórico de precios</h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <LineChart
                data={props.historico}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hastaFecha" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line name='RL1 €/kWh' type="monotone" dataKey="precioVariableRL1" stroke="#ffb119" activeDot={{ r: 8 }}/>
                <Line name='RL2 €/kWh' type="monotone" dataKey="precioVariableRL2" stroke="#46d37b" activeDot={{ r: 8 }}/>
                <Line name='RL3 €/kWh' type="monotone" dataKey="precioVariableRL3" stroke="#524cd9" activeDot={{ r: 8 }}/>
              
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
    }

    export default CmpDetalleTarifa