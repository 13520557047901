import './css/select.css';
import React from 'react';

const CmpSelect = props =>{
    const { label, value, onChange, peajes, error, title } = props;

    const listPeajes = peajes.map((peaje) =>
        <option key={peaje} value={peaje}>{peaje}</option>
    );

    return(
        <div className={`CmpSelect ${error ? "CmpSelectError" : ""}`}>
            <label>{label}</label>
            <select title={title} value={value} onChange={onChange}>
                <option value="Peaje" disabled hidden>Peaje</option>
                {listPeajes}
            </select>
        </div>
    )
}

export default CmpSelect;