import React from 'react'
import './App.css';
import CmpForm from './components/form';
import CmpGraficaIndexada from './components/graficaIndexada';
import CmpMenu from './components/menu';
import CmpQandA from './components/qanda';
import GoogleAd from './components/adsense';

function App(props) {
  const {setPrecios, setTotal} = props;

  return (
    <div className="App HomePage">
      <CmpMenu />
      <section className="App-content">
        <CmpForm setPrecios={setPrecios} setTotal={setTotal}/>
      </section>
      <CmpQandA />
      <GoogleAd slot="5092294321" googleAdId="ca-pub-3997523612422231"/>
      <CmpGraficaIndexada />
  <GoogleAd slot="5092294321" googleAdId="ca-pub-3997523612422231"/>
    </div>
  );
}

export default App;