import React, {useState, useEffect} from 'react'
import '../App.css'
import CmpTarifa from '../components/tarifa'
import './css/resultados.css'
import '../components/css/menu.css'
import CmpMenu from '../components/menu'
import CmpFilter from '../components/filter'
import CmpOrderBy from '../components/orderby'
import Offcanvas from 'react-bootstrap/Offcanvas'

function CmpResultados(props) {
  const {precios, total} = props;
  const [noResultados, setNoResultados] = useState("")
  //Constants per poder filtrar
  const [listaFiltrada, setListaFiltrada] = useState(precios)
  const [companiaSeleccionada, setCompaniaSeleccionada] = useState([])
  const [orderBy, setOrderBy] = useState("TotalAsc")
  const [ordenar, setOrdenar] = useState([])

  //Filtres checkbox
  const [mercado, setMercado] = useState([])
  const [indexada, setIndexada] = useState([])
  const [permanencia, setPermanencia] = useState([])

  //Hamburguer per filtres
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  //Destacar tarifes barates
  const [destacar, setDestacar] = useState([])
  const [contador, setContador] = useState()
  
  
  //Filtrar per mercado INI
  const filterCheckbox = (id, filterArray) => {
    //Si no esta al array, afegeix el valor dintre
    if(!filterArray.includes(id)){
      filterArray.push(id)
      return filterArray
    }
    //Si ja existeix, treure el valor del array
    const index = filterArray.indexOf(id);
    if (index > -1) {
      filterArray.splice(index, 1);
      return filterArray
    }
    return filterArray
  }
  //Filtrar per mercado FIN


  //Filtrar per companyia INI

  useEffect(() => {
    var lista = [...precios]

    if(companiaSeleccionada.length && !companiaSeleccionada.includes("")){
      lista = precios.filter(
        (compania) => companiaSeleccionada.includes(compania.nombreCompania)
      )
    }

    if(mercado.length){
      lista = precios.filter(
        (compania) => mercado.includes(compania.mercado)
      )
    }

    if(indexada.length){
      lista = precios.filter(
        (compania) => indexada.includes(compania.indexada.toString())
      )
    }

    if(permanencia.length){
      lista = precios.filter(
        (compania) => permanencia.includes(compania.permanencia.toString())
      )
    }
    
    setListaFiltrada(lista)
    setNoResultados("")
    
    if(!lista.length){
      setNoResultados(<div><img src="./NoResultados.png" alt="Tarifas de Gas sin resultados"></img><h3>Vaya, no se han encontrado resultados</h3></div>)
    }

    
  }, [mercado, companiaSeleccionada, indexada, permanencia]);
  //Filtrar per companyia FIN





  const ordenacio = () => {
    const list = [...listaFiltrada]
    if(orderBy === "TotalAsc"){
      setOrdenar(list.sort((a, b) => a.total - b.total))
    }
    if(orderBy === "TotalDesc"){
      setOrdenar(list.sort((a, b) => b.total - a.total))
    }
  }

  const handleTotals = () => {
    let contador = 0
    let destacat = []
    listaFiltrada.forEach(precio => {
      if(precio.total < total) {
        contador++
        destacat.push(precio.id)
      }
    })
    setDestacar(destacat)
    setContador(contador)
  }
  

  useEffect(() => {
    ordenacio()
    handleTotals()
  }, [orderBy, listaFiltrada])

  //Fi constants filtres

  return (
    <div className="App">
      <CmpMenu />
      <section className="App-content-resultados">
      <div className='CmpTarifas'>

        <div className='CmpFilterFiltros' onClick={handleShow}>
          <img src="/filter.png" alt="Filtrar las mejores compañías de gas"/>
          <p>Filtros</p>
        </div>

        <Offcanvas show={show} onHide={handleClose} className="CmpOffCanvas">
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CmpFilter
              setCompaniaSeleccionada={(id) => {
                setCompaniaSeleccionada([id])
              }}

              setMercado={(id) => {
                const newMercado = [...mercado]
                const filteredValue = filterCheckbox(id, newMercado)
                setMercado(filteredValue)
              }}
              setIndexada={(id) => {
                const newIndexada = [...indexada]
                const filteredValue = filterCheckbox(id, newIndexada)
                setIndexada(filteredValue)
              }}
              setPermanencia={(id) => {
                const newPermanencia = [...permanencia]
                const filteredValue = filterCheckbox(id, newPermanencia)
                setPermanencia(filteredValue)
              }}
              precios={precios}
              companiaSeleccionada={companiaSeleccionada}
              mercado={mercado}
              indexada={indexada}
              permanencia={permanencia}
            />
          </Offcanvas.Body>
        </Offcanvas>



        
          {<CmpOrderBy 
            setOrderBy={setOrderBy}
          />}

          {total && (
            <div className='TarifaMejorada'>
              {contador > 0 && `Se han encontrado ${contador} tarifas que mejoran tu precio.`}
            </div>
          )}

          {!!ordenar.length && ordenar.map((precio, i) => {
            if (i === 0 || i === 3 || i === 6 || i === 9){
              return (
                <div key={precio.id}>
                  <CmpTarifa slot="5092294321" googleAdId="ca-pub-3997523612422231"/>
                  <CmpTarifa
                  precio={precio}
                  imagen={precio.logo}
                  fijo="Término fijo"
                  energia="Energía facturada"
                  total="Total con Impuestos"
                  destacar={destacar.includes(precio.id)}
                  />
                </div>
              )
            }else{
              return <CmpTarifa
              precio={precio}
              imagen={precio.logo}
              fijo="Término fijo"
              energia="Energía facturada"
              total="Total con Impuestos"
              key={precio.id}
              destacar={destacar.includes(precio.id)}
              />
            }
            })
          }
          {noResultados}
        </div>
      </section>
    </div>
  );
}

export default CmpResultados