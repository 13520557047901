import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CmpButton from "./button";
import CmpInput from "./input";
import CmpSelect from "./select";
import "../App.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./css/form.css";
import CmpInfo from "./info";
import React from "react";
import 'reactjs-popup/dist/index.css';
import Popup from "reactjs-popup";
import GoogleAd from './adsense';

const CmpForm = (props) => {
  const { setPrecios, setTotal } = props;
  const navigate = useNavigate();
  const [energia, setEnergia] = useState("");
  const [peaje, setPeaje] = useState("Peaje");
  const [dias, setDias] = useState("");
  const [factura, setFactura] = useState("");
  const [extra, setExtra] = useState("");
  const peajes = ["RL1", "RL2", "RL3"];
  const [energiaError, setEnergiaError] = useState(false);
  const [diasError, setDiasError] = useState(false);
  const [peajeError, setPeajeError] = useState(false);
  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(!show); // Toggle accordion
  };

  const handleSubmit = () => {
    setEnergiaError(!energia);
    setPeajeError(!peajes.includes(peaje));
    //setMantError(!mantError);
    setDiasError(!dias);

    if (energia && peajes.includes(peaje) && dias) {
      const apiURL = `https://comparadorapi.elrincondelgas.com/getPrice?consumo=${energia}&peaje=${peaje}&dias=${dias}`;
      fetch(apiURL)
        .then((res) => res.json())
        .then((res) => {
          setTotal(factura)
          setPrecios(res);
          navigate("resultados");
          //window.location.href = "/resultados"
        });
    }


  };

  return (
    <div className="CmpFormGlobal">
      <CmpInfo />
      {/*Anunci Linea directa*/}
      {/*<div className="anuncioAfiliacion">
        <h2>Apoya el comparador 😉</h2><p>Calcula tu precio para cualquier seguro</p>
        <iframe src="https://www.awin1.com/cawshow.php?v=11078&s=2276255&q=349712&r=1235100&iframe=1" width="300" height="250" frameborder="0" border="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
  </div>*/}

      <form className="CmpForm" onSubmit={handleSubmit}>
        <img className="iconoFlama" src="./GasLogo98.png" alt="El Rincón del Gas Logo"></img>
        <div className="CmpFormPetit">
          <div>
            <div className="CmpFormColumna">
              <CmpInput
                label="Energía consumida"
                value={energia}
                onChange={(e) => {
                  setEnergiaError(!e.target.value)
                  setEnergia(e.target.value)
                }}
                placeholder="Energía kWh"
                error={energiaError}
              />

              <CmpInput
                label="Días"
                value={dias}
                onChange={(e) => {
                  setDiasError(!e.target.value)
                  setDias(e.target.value)
                }}
                placeholder="Días de factura"
                error={diasError}
              />
              <CmpSelect
                value={peaje}
                title={"Peaje de acceso"}
                onChange={(e) => {
                  setPeaje(e.target.value)
                  setPeajeError(peajes.includes(e.target.value))
                }}
                peajes={peajes}
                error={peajeError}
              />
              
            </div>
            <div className="CmpFormColumna">
              <div className="accordion-header" onClick={handleOpen}>
                <div>Datos Opcionales</div>
                <div className="sign">{show ? '-' : '+'}</div>
              </div>
              {show && (
                <div className="CamposAdicionales">
                  <CmpInput
                    label="Mantenimientos o extras"
                    value={extra}
                    onChange={(e) => setExtra(e.target.value)}
                    placeholder="Extras €"
                    //error={mantError}
                  />
                  <CmpInput
                    label="Total factura"
                    value={factura}
                    onChange={(e) => setFactura(e.target.value)}
                    placeholder="Total Factura €"
                  />
                  </div>
              )}
                
            </div>
          </div>
          <div className="buttonsForm">
                
          
            {/*<CmpButton onClick={handleSubmit} text="Comparar Tarifas" />*/}
            <CmpButton onClick={handleSubmit} text="Comparar Tarifas" />
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="RellenarAuto">
                  <div align="left">
                    Datos generales para:
                    <li>Piso 100m2</li>
                    <li>4 personas</li>
                    <li>2 meses de invierno</li>
                    <li>Buena aislación térmica</li>
                  </div>
                </Tooltip>
              }
            >
              <div
                id="RellenarAuto"
                className="RellenarAutoForm CmpButtonForm"
                onClick={() => {
                  setEnergia("1601");
                  setDias("61");
                  setExtra("0");
                  setPeaje("RL2");
                }}
              >
                Datos Estándar
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CmpForm;
