import React, { useEffect } from 'react';
import "./css/adsense.css"

const GoogleAd = (props) => {
  const { slot, googleAdId, classNamePersonalized } = props;

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  let dataad

  if (classNamePersonalized){
    dataad = "horizontal"
  }else{
    dataad = "auto"
  }

  const personalizedClass = `adsense ${classNamePersonalized}`
  return (
    <div className={personalizedClass}>
        <ins className='adsbygoogle'
            style={{display:'block'}}
            data-ad-client={googleAdId}
            data-ad-slot={slot}
            data-ad-format={dataad}
            data-full-width-responsive='true'></ins>
    </div>
  );
};

export default GoogleAd;
