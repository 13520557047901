import "./css/menu.css"
import React, {useState, useEffect} from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";

const CmpMenu = props =>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onClickConsultoria = () => {
        window.open("https://elrincondelgas.com/consultoria-energetica/", "_blank")
    }

    const onClickInicio = () => {
        window.location.href = "https://comparador.elrincondelgas.com/"
    }

    const onClickArticulos = () => {
        window.open("https://elrincondelgas.com/all-courses/", "_blank")
    }

    const onClickSobreMi = () => {
        window.open("https://elrincondelgas.com/about/", "_blank")
    }

    const onClickContacto = () => {
        window.open("https://elrincondelgas.com/contact/", "_blank")
    }

    //Versió desktop o mobile
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
      setWindowDimension(window.innerWidth);
    }, []);
  
    useEffect(() => {
      function handleResize() {
        setWindowDimension(window.innerWidth);
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowDimension <= 1000;
    //Versió desktop o mobile FIN

    if (!isMobile){
        return(
            <div className='CmpMenu'>
                <div className="CmpMenuLogo">
                    <a href="/">
                        <img src="./GasLogo98.png" alt="Las compañías de Gas Natural más baratas"></img>
                        <h1>El Rincón del Gas</h1>
                    </a>
                </div>
                <div className="CmpMenuMenu">
                    <div alt="Inicio" onClick={onClickInicio}>Inicio</div>
                    <div alt="Artículos sobre Gas Natural" onClick={onClickArticulos}>Artículos</div>
                    <div alt="Sobre Mi" onClick={onClickSobreMi}>Sobre Mi</div>
                    <div alt="Contacta con el Rincón del Gas" onClick={onClickContacto}>Contacto</div>
                </div>
                <div className="CmpMenuConsultoria">
                    <div onClick={onClickConsultoria} alt="Asesor para Consultoría Gas Natural" className="buttonConsultoria">Consultoría Privada</div>
                </div>
            </div>
        )
    }else{
        return(
            <div className='CmpMenu CmpMenuTlf'>
                <div className="CmpMenuLogo CmpMenuLogoTlf">
                    <a href="/">
                        <img src="./GasLogo98.png" alt="Las compañías de Gas Natural más baratas"></img>
                        <h1>El Rincón del Gas</h1>
                    </a>
                </div>
                <div className="HamburguerMenu" onClick={handleShow}>
                    <img src="./hamburguer.png" alt="Menu"></img>
                </div>

                <Offcanvas placement="end" show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="CmpMenuMenu CmpMenuMenuTlf">
                            <div alt="Inicio" onClick={onClickInicio}>Inicio</div>
                            <div alt="Artículos sobre Gas Natural" onClick={onClickArticulos}>Artículos</div>
                            <div alt="Sobre Mi" onClick={onClickSobreMi}>Sobre Mi</div>
                            <div alt="Contacta con el Rincón del Gas" onClick={onClickContacto}>Contacto</div>
                        </div>
                        <div className="CmpMenuConsultoria CmpMenuConsultoriaTlf">
                            <div onClick={onClickConsultoria} alt="Asesor para Consultoría Gas Natural" className="buttonConsultoria">Consultoría Privada</div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        )
    }
    
}

export default CmpMenu;
