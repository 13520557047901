import CmpButton from "./button";
import CmpDetalleTarifa from "./detalleTarifa"
import React, {useState, useEffect} from 'react'
import "./css/tarifa.css"
import GoogleAd from "./adsense";

const CmpTarifa = props => {
  const { precio, imagen, fijo, energia, total, destacar, slot, googleAdId } = props;
  const [modalShow, setModalShow] =  useState(false);
  let classNameTarifa = `CmpTarifa ${destacar}`

  const [dataHistorico, setDataHistorico] = useState([])

  useEffect(() => {

    const fetchData = async () => {
      let data = await fetch(`https://comparadorapi.elrincondelgas.com/getHistorico/one?idCompania=${precio.idCompania}`)
      const result = await data.json()
      setDataHistorico(result)
    }

    fetchData()
      .catch(console.error)
  }, [])



  if (!googleAdId){
    let url = precio.url
    let telefono = `tel:${precio.telefono}`
    return(
      <>
      <div className={classNameTarifa}> 
        <div className="CmpTarifa-columna">
          <img src={imagen} alt={precio.nombreCompania} className="CmpTarifa-Image"></img>
          <div className="CmpTarifa-Compania">{precio.nombreCompania}</div>
        </div>
        <div className="CmpTarifa-columna">
          <div className="CmpTarifa-title">{fijo}</div>
          <div className="CmpTarifa-precio">{precio.terminoFijo} €</div>
        </div>
        <div className="CmpTarifa-columna">
          <div className="CmpTarifa-title">{energia}</div>
          <div className="CmpTarifa-precio">{precio.energia} €</div>
        </div>
        <div className="CmpTarifa-columna">
          <div className="CmpTarifa-title">{total}</div>
          <div className="CmpTarifa-total">{precio.total} €</div>
        </div>
        <div className="CmpTarifa-butons">
          <CmpButton clase="CmpButtonContratar" onClick={()=> window.open(url, "_blank")} text="Contratar Online" />
          <div className="CmpTarifaDetalleButtons">
            <div className="CmpButton DetalleTarifa" onClick={() => setModalShow(true)}>
              Ver Detalles
            </div>
            <CmpButton onClick={()=> window.open(telefono)} text="Llamar" clase="CmpButton DetalleTarifa"/>
          </div>
          
        </div>
      </div>
      <CmpDetalleTarifa
      show={modalShow}
      onHide={() => setModalShow(false)}
      nombretarifa={precio.nombreTarifa}
      compania={precio.nombreCompania}
      varrl1={precio.varRL1}
      varrl2={precio.varRL2}
      varrl3={precio.varRL3}
      fijorl1={precio.fijoRL1}
      fijorl2={precio.fijoRL2}
      fijorl3={precio.fijoRL3}
      historico={dataHistorico}
    />
    </>
    )
  }else{
    return(
      <>
      <div className={classNameTarifa}>
        <GoogleAd slot={slot} googleAdId={googleAdId} classNamePersonalized="heightMax"/>
      </div>
      </>
    )
  }
}

export default CmpTarifa;