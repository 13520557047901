import { Link } from "react-router-dom";
import CmpButton from "./button";
import "./css/notFound.css";
import CmpMenu from "./menu";
import React from 'react';

const NotFound = () => {
  return (
    <div>
        <CmpMenu />
        <div className="CmpNotFound">
            <img src="./GasLogo98.png" alt="El Rincón del Gas"></img>
            <h1>Existen muchas tarifas de gas, pero aquí no están :)</h1>
            <Link to="/">
                <CmpButton 
                    text="Comparar Tarifas"
                />
            </Link>
        </div>
    </div>
  );
};

export default NotFound;
