import "./css/qanda.css";
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const CmpQandA = () => {

  return (
    <div className="CmpQandA">
        <h2>¿Tienes dudas sobre la factura o cambios de compañía de gas natural?<br/>Te lo explicamos</h2>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header as="h3">¿Cómo puedo cambiar el titular de un contrato?</Accordion.Header>
                <Accordion.Body>
                    Los contratos se pueden cambiar de 2 formas:
                    <ul>
                        <li>
                            <b>Subrogación:</b> Se cambia el titular pero el contrato sigue con las mismas condiciones. Esto se hace a través de un formulario en la web de la misma compañía.
                        </li>
                        <li>
                            <b>Cambio de compañía con cambio de titular:</b> Es muy importante que, si se decide esta opción,
                             informemos a la nueva compañía de que es un <b>traspaso de tarifa con cambio de titularidad.</b> 
                             De no ser así nos rechazarían el cambio de tarifa.
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header as="h3">¿Cómo se si tengo permanencia en mi compañía?</Accordion.Header>
                <Accordion.Body>
                    <p>Para saber esto deberíamos de consultar el contrato que tenemos con la compañía, allí tenemos toda la información, aunque hay que leer bastante.</p>
                    <p><b>Seguro que te preguntas:</b> ¿Y qué pasa si no tengo este contrato porque lo he perdido? En estos casos la solución
                    es muy fácil. <span role="img" aria-label="feliz">🙂</span></p>
                    <p>Puedes llamar o escribir a tu compañía actual y pedir que te envíen el contrato. están obligados a dártelo. <span role="img" aria-label="guiño">😉</span></p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header as="h3">¿Tengo que avisar a mi anterior compañía cuando hago un cambio?</Accordion.Header>
                <Accordion.Body>
                    <p>Respuesta rápida: <b>No debes de avisar a tu anterior compañía. <span role="img" aria-label="perfecto">👌</span></b></p>
                    <p>Ahora bien, ¿Por qué motivo no tengo que avisar?</p>
                    <p>En un contrato de gas hay 2 entidades, la comercializadora y la distribuidora. La comercializadora es la que conocemos todos
                        como podría ser Iberdrola, Endesa, Naturgy, etc.
                        La distribuidora es la que "hace todo el trabajo". Esta se encarga de suministrar el gas hasta tu casa, poner la instalación,
                        instalar el contador, etc. 
                    </p>
                    <p>Pues bien, esta distribuidora es la que tiene un contador asignado a un cliente y por otro lado una comercializadora.
                        Esto lo que hace es que no puede tener más de una comercializadora a la vez, lo que hace que no tengas que avisar a tu
                        anterior comercializadora, ya que la nueva ocupa el lugar de la anterior.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header as="h3">Me ha llegado una factura muy elevada ¿Qué hago?</Accordion.Header>
                <Accordion.Body>
                    <p><b>Que no cunda el pánico</b>, a lo mejor lo podemos salvar.</p>
                    <p>Lo primero que debemos de hacer es revisar el motivo (lectura estimada, consumo muy elevado, actualización de precios...). Sobre todos estos temas hay videos en el canal de YouTube:</p>
                    <a href="https://www.youtube.com/c/ElRincóndelGas" alt="El Rincón del Gas Youtube">El Rincón del Gas - Canal de YouTube</a>
                    <p>
                    Si has revisado todos los puntos y ves que todo correcto, la única opción que tienes es empezar a ahorrar a partir de esa factura. Te recomiendo que uses el comparador que tienes un poco más arriba, para ver si puedes ahorrar en tu tarifa <span role="img" aria-label="guiño">🙂</span>
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header as="h3">¿Qué es el peaje RL…? ¿Puedo escoger el que quiera?</Accordion.Header>
                <Accordion.Body>
                    <p>Los peajes son una forma de cuantificar tu consumo anual de gas, de esta forma te asignan un precio para el variable y otro para el fijo mensual.</p>
                    <p>Por lo general cuanto más alto es el peaje, más pagas de fijo pero menos de variable. Por otro lado, cuanto más bajo es el peaje, se paga menos de fijo pero más de variable.</p>
                    <p>Si quieres saber más acerca de los peajes, te dejo uno de mis videos donde lo explico.</p>
                    <a href="https://youtu.be/LvOVnTxEGg0" alt="Peajes y cargos Gas Natural">Peajes y Cargos Gas Natural</a>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header as="h3">Me ha llegado una lectura estimada ¿Qué hago?</Accordion.Header>
                <Accordion.Body>
                    En estos casos lo que hago es diferenciar entre si la lectura es desorbitada o se acerca al consumo real:
                    <ul>
                        <li>
                            <b>Lectura estimada desorbitada:</b> En este caso se debe avisar a la compañía por llamada o por escrito, que han hecho una lectura estimada muy superior a lo habitual y que no puedes pagarlo. Una vez hecho esto, deberemos de seguir el procedimiento que nos pidan que seguramente será enviar una lectura.
                        </li>
                        <li>
                            <b>Lectura estimada cercana a la realidad:</b> En estos casos no suelo hacer nada, ya que por un importe bajo lo facturaran
                             igual el siguiente mes. Se puede hacer el mismo procedimiento que el anterior, 
                             pero no es recomendable <span role="img" aria-label="Carita Sonriente">😉</span>
                        </li>
                    </ul>
                    <p>Para evitar que esto vuelva a pasar, te recomiendo que uses la aplicación “YoLeoGas”. 
                        Te dejo un video en YouTube donde te explico todo sobre esta app.</p>
                    <a href="https://youtu.be/lcgPAgO5MOc" alt="Como enviar lecturas con la aplicación YoLeoGas">Como enviar lecturas con la aplicación YoLeoGas</a>

                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  );
};

export default CmpQandA;
